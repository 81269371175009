import mapVerify from '@/utils/formValidate'

export const getTableItem = (that, row) => {
  const tableItem = [
    {
      prop: 'name',
      component: 'elInput', // 如果传入 component 则会展示指定的组件
      label: '姓名',
      isShow: true,
      rules: mapVerify(['inputLen4']) // 支持rule校验 可用规则详见 @/utils/formValidate
    },
    {
      prop: 'date',
      label: '日期',
      isShow: true,
      component: 'elInput'
    }
  ]
  return tableItem
}
