<template>
  <el-tabs v-model="activeName" class="demos">
    <el-tab-pane label="标准table列表" name="tableDemo">
      <el-alert type="success" :closable="false">
        <p>标准table, 依赖组件 <code>TableForm</code> 支持配置 多选, 序号, 操作, 分页, 支持 formatter </p>
        <p>代码示例位置: <code>src/views/editBaseDemo/tableDemo/index.vue</code> </p>
        <p>组件支持属性与方法详见 <code>src/components/tableForm/index.vue</code> </p>
      </el-alert>
      <tableDemo class="tableDemo" />
    </el-tab-pane>
    <el-tab-pane label="编辑型Table" name="editTable">
      <el-alert type="success" :closable="false">
        <p>编辑型table, 依赖组件 <code>TableForm</code> 支持配置 多选, 序号, 操作, 分页等</p>
        <p>代码示例位置: <code>src/views/editBaseDemo/editTableDemo/index.vue</code> </p>
        <p>组件支持属性与方法详见 <code>src/components/tableForm/index.vue</code> </p>
      </el-alert>
      <editTableDemo class="tableDemo" />
    </el-tab-pane>
    <el-tab-pane label="混合型Table" name="third">
      <el-alert type="success" :closable="false">
        <p>混合型table, 依赖组件 <code>TableForm</code> 支持配置 多选, 序号, 操作, 分页等</p>
        <p>代码示例位置: <code>src/views/editBaseDemo/editTableDemo/index.vue</code> </p>
        <p>组件支持属性与方法详见 <code>src/components/tableForm/index.vue</code> </p>
      </el-alert>
      <blendTableDemo class="tableDemo" />
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import tableDemo from './tableDemo'
import editTableDemo from './editTableDemo'
import blendTableDemo from './blendTableDemo'

export default {
  components: { tableDemo, editTableDemo, blendTableDemo },
  data() {
    return {
      activeName: 'tableDemo'
    }
  },
  computed: {},
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.demos {
  height: 100%
}

.tableDemo {
  height: 500px
}
</style>
