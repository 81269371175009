export const getTableItem = (that, options) => {
  const tableItem = [
    // 容器编码
    {
      prop: 'date',
      label: '日期', // 展示的label
      width: 160, // 可以使用自定义宽度
      isShow: true // isShow 必须存在, 不是true则不展示
    },
    {
      prop: 'name',
      label: '姓名',
      isShow: true,
      // 支持使用 formatter 对数据进行二次处理
      formatter(row, prop, value, $index) {
        return `${value}(${row.age})`
      }
    },
    {
      prop: 'address',
      label: '地址',
      isShow: true
    }
  ]
  return tableItem
}
