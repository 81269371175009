<template>
  <div>
    <table-form
      :table-item="baseFormData"
      :table-data.sync="tableData"
      :page-data="pageData"
      :extend-config="tableExtendConfig"
      @delItem="delItem"
    >
      <!-- <template #dateSlot="{ row }">
        <el-input v-model="row.date" />
      </template> -->
    </table-form>
  </div>
</template>
<script>
import TableForm from '@/components/tableForm/index.vue'
import { getTableItem } from './data.js'

export default {
  components: { TableForm },
  data() {
    return {
      tableData: [
        { date: '2016-05-02', name: '王小虎', age: 11, address: '上海市普陀区金沙江路 1518 弄' },
        { date: '2016-05-03', name: '王小虎', age: 12, address: '上海市普陀区金沙江路 1519 弄' },
        { date: '2016-05-04', name: '王小虎', age: 13, address: '上海市普陀区金沙江路 1520 弄' },
        { date: '2016-05-05', name: '王小虎', age: 14, address: '上海市普陀区金沙江路 1521 弄' }
      ],
      tableExtendConfig: {
        sortNum: true,
        checkBox: true,
        operateWidth: '80px',
        operate: [
          {
            event: 'delItem',
            confirm: true,
            confirmMessage: '是否删除',
            label: '删除',
            customClass: 'danger'
          }
        ]
      },
      pageData: {
        pageSize: 5,
        currentPage: 1,
        recordCount: 100
      }
    }
  },
  computed: {
    baseFormData() {
      return getTableItem()
    }
  },
  created() {},
  methods: {
    delItem() {
      this.$message.error('删除失败')
    }
  }
}
</script>
