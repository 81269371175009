<template>
  <div>
    <!-- table-item, table-data 是必填 -->
    <!-- 不传page-data 则不展示分页器 -->
    <table-form
      :table-item="baseFormData"
      :table-data.sync="tableData"
      :extend-config="tableExtendConfig"
      :page-data="pageData"
      @delItem="delItem"
      @pageChange="pageChange"
    />
  </div>
</template>
<script>
import TableForm from '@/components/tableForm/editBase.vue'
import { getTableItem } from './data.js'

export default {
  components: { TableForm },
  data() {
    return {
      tableData: [
        { date: '2016-05-02', name: '王小虎', age: 11, address: '上海市普陀区金沙江路 1518 弄' },
        { date: '2016-05-03', name: '王小虎', age: 12, address: '上海市普陀区金沙江路 1519 弄' },
        { date: '2016-05-04', name: '王小虎', age: 13, address: '上海市普陀区金沙江路 1520 弄' },
        { date: '2016-05-05', name: '王小虎', age: 14, address: '上海市普陀区金沙江路 1521 弄' }
      ],
      tableExtendConfig: {
        sortNum: true,
        checkBox: true,
        operateWidth: '180px',
        operate: []
      },

      pageData: {
        pageSize: 5,
        currentPage: 1,
        recordCount: 100
      }
    }
  },
  computed: {
    baseFormData() {
      return getTableItem()
    }
  },
  created() {},
  methods: {
    delItem() {
      this.$message.error('删除失败')
    },
    pageChange() {
      this.$message.success('分页数据变更')
    }
  }
}
</script>
